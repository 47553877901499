<template>
  <div>
    <ClientsGrid :isDigital="1"></ClientsGrid>
  </div>
</template>
<script>
import ClientsGrid from "@/views/commons/components/clients/components/Clients.vue";

export default {
  components: {
    ClientsGrid,
  },
  data() {
    return {
    };
  },
};
</script>